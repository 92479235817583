import { useState, memo, useCallback } from 'react';
// router
import { useNavigate } from 'react-router';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
// redux
import { useSelector } from 'src/redux/store';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useEventListener } from 'src/hooks/use-event-listener';
// Typesense
import { clientTypesense } from 'src/utils/typesense';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';
//
import ResultItem from './result-item';

// ----------------------------------------------------------------------

function Searchbar() {
  const theme = useTheme();

  const search = useBoolean();

  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');

  const [searchQuery, setSearchQuery] = useState('');
  const [respData, setRespData] = useState([]);

  const { user } = useSelector((state) => state.user);

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  const handleClick = useCallback(
    (id) => {
      navigate(`/dashboard/crm/contacts/account?id=${id}`);
      handleClose();
    },
    [navigate, handleClose]
  );

  const [timer, setTimer] = useState(null);
  const handleSearch = useCallback(
    (event) => {
      setSearchQuery(event.target.value);
      if (event.target.value === '') return;
      // Limpiar el temporizador existente
      if (timer) clearTimeout(timer);
      // Crear un nuevo temporizador
      const newTimer = setTimeout(async () => {
        const searchParameters = {
          q: event.target.value,
          query_by: 'full_name,email,cell_phone_number,mailing_address',
          filter_by: `owner_id:=${user.id}&&status:=true`,
          per_page: 10,
          infix: 'always,always,always,always',
        };
        // Realizar una búsqueda en Typesense
        const response = await clientTypesense
          .collections('contact')
          .documents()
          .search(searchParameters);
        setRespData(response.hits);
      }, 500); // Retrasar la ejecución de la búsqueda en 500 milisegundos
      // Establecer el nuevo temporizador
      setTimer(newTimer);
    },
    [timer, user]
  );

  const notFound = searchQuery && !respData.length;

  const renderItems = () => (
    <List disablePadding>
      {respData.map((item) => {
        const { id, full_name, email, cell_phone_number, mailing_address } =
          item.document;
        return (
          <ResultItem
            full_name={full_name}
            email={email}
            phone={cell_phone_number}
            address={mailing_address}
            key={id}
            onClickItem={() => handleClick(id)}
          />
        );
      })}
    </List>
  );

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>
      {mdUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>}
    </Stack>
  );

  return (
    <>
      {renderButton}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 2, pt: 2, height: 400 }}>
          {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
        </Scrollbar>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);
