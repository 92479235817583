import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// routes
import { paths } from 'src/routes/paths';

import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
  TableSelectedAction,
} from 'src/components/table';
//
import { useGetIncomingPhoneNumbersActive, useGetTwilioAvailablePhoneNumbers } from 'src/api/twilio';
// Redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/redux/store';
import { setAreaCode, setPhoneNumberBuy, setTab } from 'src/redux/slices/twilio-available-phone-number';
// mock
import { phonenumberActive } from 'src/_mock/_contact_center';
// navigation
import { useNavigate } from 'react-router';
// Components
import Label from 'src/components/label';
import UserTableToolbar from '../user-table-toolbar';
import PhoneNumberTableRow from '../phone-number-table-row';
import PhoneNumberActiveTableRow from '../phone-number-active-table-row';
import PhoneTableToolbar from '../phone-table-toolbar';
import TableSkeletonPhoneNumber from '../table-skeleton-phone-number';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'phone_number', label: 'Phone Number' },
  { id: 'type', label: 'Type', width: 110 },
  { id: 'voice', label: 'Voice', width: 110, align: 'center' },
  { id: 'sms', label: 'SMS', width: 110, align: 'center' },
  { id: 'mms', label: 'MMS', width: 110, align: 'center' },
  { id: 'price', label: 'Monthly Fee', width: 150 },
  { id: 'current_price', label: '', width: 200 }
];

const TABLE_HEAD_PHONENUMBER_ACTIVE = [
  { id: 'phone_number', label: 'Phone Number' },
  { id: 'type', label: 'Type', width: 150 },
  { id: 'voice', label: 'Voice', width: 150, align: 'center' },
  { id: 'sms', label: 'SMS', width: 150, align: 'center'},
  { id: 'mms', label: 'MMS', width: 150, align: 'center'},
  { id: 'a2p', label: 'A2P Status', width: 180, align: 'center' },
];
// ----------------------------------------------------------------------


function a11yProps(index) {
  return {
    id: `phonenumber-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

export default function PhoneNumberListView() {

  // const { pricings: dataPricing } = useGetTwilioPrices();
  const params = useSelector(state => state.twilioAvailablePhoneNumber);
  const { tab } = useSelector(state => state.twilioAvailablePhoneNumber);

  const [filters, setFilters] = useState(defaultFilters);
  const [stages_filter, setStages_filter] = useState([]);

  const { 
    availablephonenumbers, 
    availablephonenumbersEmpty, 
    availablephonenumbersLoading, 
    availablephonenumbersMutate } = useGetTwilioAvailablePhoneNumbers(params);

  const { 
    incomingPhoneNumbersActive, 
    incomingPhoneNumbersActiveEmpty, 
    incomingPhoneNumbersActiveLoading } = useGetIncomingPhoneNumbersActive();

  const dispatch = useDispatch();

  const table = useTable({ defaultDense: true, defaultRowsPerPagep: 30 });
  const navigate = useNavigate();

  const settings = useSettingsContext();
  
  const [tableData ] = useState([]);
  const [ value, setValue ] = useState(tab || 'active');

  const dataFiltered = applyFilter({
    inputData: incomingPhoneNumbersActive,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const handleFilters = useCallback(
    (name, value_f) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value_f,
      }));
    },
    [table]
  );

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    dispatch(setTab({ tab: newValue }))
  }

  const handleChangeAreaCode = (event) => {
    const { value_c } = event.target
    if(value_c.length === 3){
      dispatch(setAreaCode({ areaCode: value_c }));
      availablephonenumbersMutate();
    }
  }

  const handleBuyANumber = (phoneNumberItem) => {
    const { phoneNumber, friendlyName } = phoneNumberItem;
    dispatch(setPhoneNumberBuy({ phoneNumber, price: 1.15, friendlyName }));
    navigate('/dashboard/contact_center/buy_phone_number');
  }

  const denseHeight = table.dense ? 72 : 81;


  return (
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Phone Numbers', href: paths.dashboard.user.list },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs 
          value={value} 
          onChange={handleTabChange} 
          sx={{
                px: 2.5,
                boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}>
          {['active', 'buy a number'].map((tab_n) => (
            <Tab
              key={tab_n}
              iconPosition="end"
              value={tab_n}
              label={tab_n}
              {...a11yProps(tab_n)} 
              icon={
                tab_n !== 'buy a number' &&
                <Label
                  variant='soft'
                  color={(tab_n === 'active' && 'success') || 'error'}
                >
                  {incomingPhoneNumbersActive.length || 0}
                </Label>
              }
              sx={{ textTransform: 'capitalize' }}
            />
          ))}
        </Tabs>

        <CustomTabPanel value={value} index='active'>
        <UserTableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          roleOptions={stages_filter}
        />
        <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960, height: 1 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD_PHONENUMBER_ACTIVE}
                  rowCount={tableData.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {incomingPhoneNumbersActiveLoading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeletonPhoneNumber key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                        {
                          dataFiltered.map((item, index) => (
                            <PhoneNumberActiveTableRow row={item} key={index} /> 
                          ))
                        }


                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />
                  {incomingPhoneNumbersActiveEmpty && <TableNoData notFound={incomingPhoneNumbersActiveEmpty} />}
                </TableBody>
              </Table>
            </TableContainer>
          </TableContainer>
        </Scrollbar>
        <TablePaginationCustom
            count={50}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index='released'>
          <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960, height: 1 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD_PHONENUMBER_ACTIVE}
                  rowCount={tableData.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {availablephonenumbersLoading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeletonPhoneNumber key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                        {
                          phonenumberActive.map((item, index) => (
                            <PhoneNumberActiveTableRow row={item} key={index} /> 
                          ))
                        }


                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />
                  {availablephonenumbersEmpty && <TableNoData notFound={availablephonenumbersEmpty} />}
                </TableBody>
              </Table>
            </TableContainer>
          </TableContainer>
        </Scrollbar>
        </CustomTabPanel>

        <CustomTabPanel value={value} index='buy a number'>
        <PhoneTableToolbar onChange={handleChangeAreaCode}/>

        <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960, height: 1 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {availablephonenumbersLoading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeletonPhoneNumber key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                        {
                          availablephonenumbers.map((item, index) => (
                            <PhoneNumberTableRow row={item} key={index} onClick={handleBuyANumber} /> 
                          ))
                        }


                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />
                  {availablephonenumbersEmpty && <TableNoData notFound={availablephonenumbersEmpty} />}
                </TableBody>
              </Table>
            </TableContainer>
          </TableContainer>
        </Scrollbar>
          <TablePaginationCustom
            count={availablephonenumbers.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </CustomTabPanel>
        </Card>
      </Container>
  );
}

PhoneNumberListView.propTypes = {
  route: PropTypes.object,
};



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function applyFilter({ inputData = [], comparator, filters }) {
  const { name, status, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (phonenumber) => phonenumber.phoneNumber.includes(name.toString())
    );
  }

  return inputData;
}


CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};