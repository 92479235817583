import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);


const icon_iconify = (name, size=24) => (
  <Iconify icon={name}  sx={{ fontSize:24 }}/>
);

const ICONS = {
  blog: icon('ic_blog'),
  crm: icon('ic_user'),
  external: icon('ic_external'),
  dashboard: icon('ic_dashboard'),
  editor: icon_iconify('solar:clapperboard-edit-bold'),
  billing: icon_iconify('solar:bill-check-bold'),  
  reviews: icon_iconify('solar:star-circle-bold-duotone'), 
  invoice: icon('ic_invoice'),
  label: icon('ic_label'),
  kanban: icon('ic_kanban'),
  setup: icon_iconify('solar:users-group-rounded-bold-duotone'),   
  call: icon_iconify('solar:call-chat-bold-duotone'),   
  
};

// ----------------------------------------------------------------------

export function useNavData(subdomain) {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'overview',
        items: [
          { title: 'Home', path: paths.dashboard.root, icon: ICONS.dashboard },
          {
            title: 'CRM',
            path: paths.dashboard.crm.root,
            icon: ICONS.crm,
            children: [
              { title: 'Contacts', path: paths.dashboard.crm.contacts.list },
              { title: 'Companies', path: paths.dashboard.crm.companies.list },
              { title: 'Leads', path: paths.dashboard.crm.leads.list },
              { title: 'Opportunities', path: paths.dashboard.crm.opportunities.list },
              { title: 'Campaigns', path: paths.dashboard.crm.campaigns.list },
              { title: 'CRM Settings', path: paths.dashboard.crm.settings.list },
            ],
          },   
          {
            title: 'blog',
            path: paths.dashboard.post.root,
            icon: ICONS.blog,
            children: [
              { title: 'list', path: paths.dashboard.post.root },
              { title: 'create', path: paths.dashboard.post.new },
            ],
          },          
          {
            title: 'reviews',
            path: paths.dashboard.job.root,
            icon: ICONS.label,
            children: [
              { title: 'list', path: paths.dashboard.job.root },
              { title: 'create', path: paths.dashboard.job.new },
            ],
          },
          { title: 'gallery', path: paths.dashboard.product.new, icon: ICONS.kanban },
          { title: 'setup', path: paths.dashboard.user.profile, icon: ICONS.setup },
          { 
            title: 'users',
            path: paths.dashboard.users.root,
            icon: ICONS.setup,
            children: [
              { title: 'List', path: paths.dashboard.users.users.list },
              { title: 'Teams', path: paths.dashboard.users.teams.list },
              { title: 'Permission Sets', path: paths.dashboard.users.permissions.list },
            ],
          },
          { title: 'billing', path: paths.dashboard.blank, icon: ICONS.invoice },
          { title: 'preview', path: `https://${subdomain}.webfinder.ai/`, icon: ICONS.external },
          {
            title: 'contact_center',
            path: paths.dashboard.contact_center.root,
            icon: ICONS.call,
            children: [
              { title: 'calls', path: paths.dashboard.contact_center.calls },
              { title: 'messages', path: paths.dashboard.contact_center.messages },
              { title: 'phone_numbers', path: paths.dashboard.contact_center.phone_numbers },
              { title: 'usage', path: paths.dashboard.contact_center.usage },
            ],
          },
        ],
      },
    ],
    [subdomain]
  );

  return data;
}
