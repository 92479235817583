import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
// utils
import { fetcher, endpoints, postFetcher } from 'src/utils/axios';
import { useMemo } from 'react';

export function useGetTwilioPrices() {
    const URL = endpoints.twilio.prices;
    
  
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  
    const memoizedValue = useMemo(
      () => ({
        pricings: data?.pricings || [],
        pricingsLoading: isLoading,
        pricingsError: error,
        pricingsValidating: isValidating,
        pricingsEmpty: !isLoading && !data?.pricings.length,
      }),
      [data?.pricings, error, isLoading, isValidating]
    );
  
    return memoizedValue;
  }

export function useGetTwilioAvailablePhoneNumbers(params) {

    const URL = [endpoints.twilio.available_phone_numbers, { params }];
    
    const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);
  
    const memoizedValue = useMemo(
      () => ({
        availablephonenumbers: data?.data || [],
        availablephonenumbersLoading: isLoading,
        availablephonenumbersError: error,
        availablephonenumbersValidating: isValidating,
        availablephonenumbersMutate: mutate,
        availablephonenumbersEmpty: !isLoading && !data?.data.length,
      }),
      [data?.data, error, isLoading, isValidating, mutate]
    );
  
    return memoizedValue;
}

export function useGetTwilioFetchAvailablePhoneNumbers(params) {
  console.log('params', params);
  const URL = [endpoints.twilio.fetch_incoming_phone_numbers, { params }];
  
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      availablephonenumbers: data?.data || [],
      availablephonenumbersLoading: isLoading,
      availablephonenumbersError: error,
      availablephonenumbersValidating: isValidating,
      availablephonenumbersEmpty: !isLoading && !data?.data.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;

}

export function usePostIncomingPhoneNumbers() {

  const URL = endpoints.twilio.inconming_phone_number.buy;
  
  const { trigger, isMutating, data, error } = useSWRMutation(URL, postFetcher);

  const memoizedValue = useMemo(
    () => ({
      incomingPhoneNumbers: data?.data || [],
      incomingPhoneNumbersLoading: isMutating,
      incomingPhoneNumbersError: error,
      incomingPhoneNumbersTrigger: trigger,
      // availablephonenumbersEmpty: !isMutating && !data?.data?.length || 0,
    }),
    [data?.data, error, isMutating, trigger]
  );

  return memoizedValue;
}

export function useGetIncomingPhoneNumbersActive() {

  const URL = endpoints.twilio.inconming_phone_number.active;
  
  const { data, isLoading, error, isValidating  } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      incomingPhoneNumbersActive: data?.data || [],
      incomingPhoneNumbersActiveLoading: isLoading,
      incomingPhoneNumbersActiveError: error,
      incomingPhoneNumbersActiveValidating: isValidating,
      incomingPhoneNumbersActiveEmpty: !isLoading && !data?.data.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

  