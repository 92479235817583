// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = {
  url: process.env.REACT_APP_MAPBOX_API,
  public_token: process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN,
  access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  api_version: process.env.REACT_APP_MAPBOX_API_VERSION
};

export const OPENSTREETMAP_API = {
  url: process.env.REACT_APP_OPENSTREETMAP_API,
};

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

export const GOOGLE_API = {
  maps_api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
}

export const BRIDGE_DATA_OUT_PUT = {
  api_url: process.env.REACT_APP_BRIDGE_API_URL,
  token: process.env.REACT_APP_BRIDGE_API_KEY,
  dataset: process.env.REACT_APP_BRIDGE_DATASET
}

export const SALESRUSHAPI = {
  api_url: process.env.REACT_APP_SALESRUSH_API,
}

export const STRIPE = {
  client_secret: process.env.REACT_APP_STRIPE_CLIENT_SECRET,
  monthly_id: process.env.REACT_APP_STRIPE_PLAN_MONTHLY,
  yearly_id: process.env.REACT_APP_STRIPE_PLAN_YEARLY,
}

export const RADAR = {
  api_key: process.env.REACT_APP_RADAR_API_KEY
}

export const APP_CONFIG = {
  app_main_domain: process.env.REACT_APP_MAIN_DOMAIN,
}

export const TYPESENSE_CONFIG = {
  host: process.env.REACT_APP_TYPESENSE_HOST,
  port: process.env.REACT_APP_TYPESENSE_PORT,
  protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL,
  api_key: process.env.REACT_APP_TYPESENSE_API_KEY,
}