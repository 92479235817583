import { memo } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
import { Link } from 'react-router-dom';

const PhoneNumberActiveTableRow = memo(({ row  }) => {
    
    const { friendlyName, a2p, sid } = row;

    return(
    <TableRow>
        <TableCell>
          <Link to={`/dashboard/contact_center/phone_numbers/${sid}`} style={{ textDecoration: 'none', color: 'black' }}>{ friendlyName }</Link>
        </TableCell>
        <TableCell>Local</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
            <Iconify
                icon="lets-icons:check-fill"
                sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                /></TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
            <Iconify 
                icon="lets-icons:check-fill"
                sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
            <Iconify 
                icon="lets-icons:check-fill"
                sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
            {
                a2p && <Iconify 
                icon="lets-icons:check-fill"
                sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                /> 
                || <Iconify
                icon="jam:alert-f"
                sx={{
                    color: (theme) => theme.palette.warning.main,
                  }}
                />
            }
        </TableCell>
    </TableRow>)
    })

export default PhoneNumberActiveTableRow;

PhoneNumberActiveTableRow.propTypes = {
    row: PropTypes.object,
 };
