import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import checkoutReducer from './slices/checkout';
import userReducer from './slices/user';
import newHomeReducer from './slices/new-home';  
import bridgeParamReducer from './slices/bridge-param';
import postsReducer from './slices/posts';
import contactsReducer from './slices/contacts';
import contact_notesReducer from './slices/contact_notes';
import reviewsReducer from './slices/reviews';
import galleryReducer from './slices/gallery';
import productsReducer from './slices/subscriptions';
// services
import bridgeDataOutPutBaseQuery from './services/bridgedataoutput/api';
import validatorPhoneAndEmailBaseQuery from './services/salesrush/api';
import blogBaseQuery from './services/blog/api';
import twilioAvailablePhoneNumberReducer from './slices/twilio-available-phone-number';

// ----------------------------------------------------------------------

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
};

const contactsPersistConfig = {
  key: 'contacts',
  storage,
  keyPrefix: 'redux-',
};

const reviewsPersistConfig = {
  key: 'review',
  storage,
  keyPrefix: 'redux-',
};

const galleryPersistConfig = {
  key: 'gallery',
  storage,
  keyPrefix: 'redux-',
};

const postsPersistConfig = {
  key: 'posts',
  storage,
  keyPrefix: 'redux-',
};
const contact_notesPersistConfig = {
  key: 'contact_notes',
  storage,
  keyPrefix: 'redux-',
};

export const rootReducer = combineReducers({
  [bridgeDataOutPutBaseQuery.reducerPath]: bridgeDataOutPutBaseQuery.reducer,
  [validatorPhoneAndEmailBaseQuery.reducerPath]: validatorPhoneAndEmailBaseQuery.reducer,
  [blogBaseQuery.reducerPath]: blogBaseQuery.reducer,
  checkout: checkoutReducer,
  user: persistReducer(userPersistConfig, userReducer),
  posts: persistReducer(postsPersistConfig, postsReducer),
  reviews: persistReducer(reviewsPersistConfig, reviewsReducer),
  gallery: persistReducer(galleryPersistConfig, galleryReducer),
  contacts: persistReducer(contactsPersistConfig, contactsReducer),
  contact_notes: persistReducer(contact_notesPersistConfig, contact_notesReducer),
  newhome: newHomeReducer,
  bridgeParam: bridgeParamReducer,
  products: productsReducer,
  twilioAvailablePhoneNumber: twilioAvailablePhoneNumberReducer,
});
