import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
//
import { useDispatch, useSelector } from 'src/redux/store';
//
import { useLazyStripeSetCustomerDefaultPaymentMethodQuery, useLazyStripeUpdateSubscriptionQuery } from 'src/redux/services/salesrush/endpoints';
import { setUpdateCustomer, setUpdatePayment, setUpdateSubscription } from 'src/redux/slices/user';
import { enqueueSnackbar } from 'notistack';
import { STRIPE_PLANS } from 'src/constant';
import PaymentNewCardDialog from './payment-new-card-dialog';
// ----------------------------------------------------------------------

const PAYMENT_OPTIONS = [
  {
    value: 'credit',
    label: 'Credit / Debit Card',
  },
];

// ----------------------------------------------------------------------

export default function PaymentMethods({ isSelectPlan = false, onChange}) {
  const newCard = useBoolean();

  const [method, setMethod] = useState('credit');
  const [ updatePlanApi ] = useLazyStripeUpdateSubscriptionQuery();

  const { subscription } = useSelector(state => state.user);

  const dispatch = useDispatch();

  const handleChangeMethod = useCallback((newValue) => {
    setMethod(newValue);
  }, []);

  const handleChangePlan = async (event) => {
    const { value } = event.target;
    if(isSelectPlan) {
      return;
    }
    const subscriptionUpdate = await updatePlanApi({ subscription_id: subscription.id, sub_item_id: subscription.items.data[0].id, price_id: value }).unwrap();
    dispatch(setUpdateSubscription({ subscription: subscriptionUpdate.subscription }));
    enqueueSnackbar('Plan Updated', { variant: 'success' });
  }

  return (
    <>
      <Stack spacing={5}>
        <Typography variant="h6">Payment Method</Typography>

        <Stack spacing={3}>
        {PAYMENT_OPTIONS.map((option) => (
            <OptionItem
              key={option.label}
              option={option}
              selected={method === option.value}
              isCredit={option.value === 'credit' && method === 'credit'}
              onOpen={newCard.onTrue}
              returnValue={isSelectPlan}
              onChange={onChange}
              onClick={() => handleChangeMethod(option.value)}
            />
          ))}
        </Stack>
        {
          !isSelectPlan && (
            <Stack
              spacing={2.5}
              alignItems="flex-end"
              sx={{
                pt: 2.5,
              }}
            >
              <TextField select fullWidth defaultValue={subscription.plan.id} label="Select Plan" SelectProps={{ native: true }} onChange={handleChangePlan}>
                {STRIPE_PLANS.map(({ id,  name, price}) => (
                  <option key={id} value={id}>
                    {`$${price} - ${name}`}
                  </option>
                ))}
              </TextField>
            </Stack>
          )
        }
      </Stack>

      <PaymentNewCardDialog isDefault={!isSelectPlan} xs={{ width: 1200 }} open={newCard.value} onClose={newCard.onFalse} />
    </>
  );
}

PaymentMethods.propTypes = {
  isSelectPlan: PropTypes.bool,
  onChange: PropTypes.func,
}

function OptionItem({ returnValue = false, onChange, option, selected, isCredit, onOpen, ...other }) {

  const { payment_method, list_payment_methods, customer } = useSelector(state => state.user);
  const [ setDefaultPaymentApi ] = useLazyStripeSetCustomerDefaultPaymentMethodQuery();
  // const [ paymentMethodSelect,  setPaymentMethodSelect] = useState(payment_method?.id || 0);
  const dispatch = useDispatch();
  const { value, label } = option;

  const handleChangeMethod = async ( event ) => {
    const { value_cm } = event.target;
    if(returnValue) {
      onChange(value_cm);
      return;
    }
    const { 
      customer: customerUpdated, 
      payment_method: payment_method_updated } = await setDefaultPaymentApi({ customer_id: customer.id, payment_method_id: value }).unwrap();
    enqueueSnackbar('Payment Default Updated', { variant: 'success' });
    dispatch(setUpdateCustomer({ customer: customerUpdated }));
    dispatch(setUpdatePayment({ payment_method: payment_method_updated }));
  }

/*   const setDefaultPaymentMethod = async() => {
    const { 
      customer: customerUpdated, 
      payment_method: payment_method_updated } = await setDefaultPaymentApi({ customer_id: customer.id, payment_method_id: paymentMethodSelect }).unwrap();
    enqueueSnackbar('Payment Default Updated', { variant: 'success' });
    dispatch(setUpdateCustomer({ customer: customerUpdated }));
    dispatch(setUpdatePayment({ payment_method: payment_method_updated }));
  } */

  return (
    <Paper
      variant="outlined"
      key={value}
      sx={{
        p: 2.5,
        cursor: 'pointer',
        ...(selected && {
          boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
        }),
      }}
      {...other}
    >
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center">
            <Iconify
              icon={selected ? 'eva:checkmark-circle-2-fill' : 'eva:radio-button-off-fill'}
              width={24}
              sx={{ mr: 2, color: selected ? 'primary.main' : 'text.secondary' }}
            />

            <Box component="span" sx={{ flexGrow: 1 }}>
              {label}
            </Box>

            <Stack spacing={1} direction="row" alignItems="center">
              {value === 'credit' && (
                <>
                  <Iconify icon="logos:mastercard" width={24} />,
                  <Iconify icon="logos:visa" width={24} />
                </>
              )}
              {value === 'paypal' && <Iconify icon="logos:paypal" width={24} />}
              {value === 'cash' && <Iconify icon="solar:wad-of-money-bold" width={24} />}
            </Stack>
          </Stack>
        }
        primaryTypographyProps={{ typography: 'subtitle2' }}
      />

      {isCredit && (
        <Stack
          spacing={2.5}
          alignItems="flex-start"
          sx={{
            pt: 2.5,
          }}
        >
         {
          payment_method || list_payment_methods && (
            <TextField select fullWidth defaultValue={payment_method?.id || list_payment_methods[0]?.id} label="Cards" SelectProps={{ native: true }} onChange={handleChangeMethod} >
            {list_payment_methods && list_payment_methods.length > 0 && 
              list_payment_methods.map(({ id, card }) => (
              <option key={id} value={id}>
                {`**** **** **** ${card.last4} ${id === payment_method?.id ? ' - Default' : ''}`}
              </option>
            ))}
          </TextField>
          )
         }

          <Button
            size="small"
            color="primary"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={onOpen}
          >
            Add New Card
          </Button>
        </Stack>
      )}
    </Paper>
  );
}

OptionItem.propTypes = {
  isCredit: PropTypes.bool,
  onOpen: PropTypes.func,
  option: PropTypes.object,
  selected: PropTypes.bool,
  returnValue: PropTypes.bool,
  onChange: PropTypes.func,
};