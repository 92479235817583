import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';


const PhoneNumberTableRow = ({ row, onClick  }) => {
    
    const { friendlyName, isoCountry, locality, region } = row;

    return(
    <TableRow sx={{ textAlign: 'center' }}>
        <TableCell>{ friendlyName }
            <Typography 
            component="p" 
            variant='subtitle1' 
            sx={{ color: 'text.secondary', fontSize: 12 }}>{`${locality}${!region ? '' : `, ${region}`}, ${isoCountry}`}</Typography>
        </TableCell>
        <TableCell>Local</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
        <Iconify 
        icon="lets-icons:check-fill"
        sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        /></TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
        <Iconify 
        icon="lets-icons:check-fill"
        sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        /></TableCell>
        <TableCell sx={{ textAlign: 'center' }}><Iconify 
        icon="lets-icons:check-fill"
        sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        /></TableCell>
        <TableCell>$1.15</TableCell>
        <TableCell><Button variant="text" color="primary" onClick={()=> onClick(row)}>Purchase Now</Button></TableCell>
    </TableRow>)
    }

export default PhoneNumberTableRow;

PhoneNumberTableRow.propTypes = {
    row: PropTypes.object,
    onClick: PropTypes.func
 };
